<template>
  <CCard>
    <div
      id="overlay"
      ref="overlay"
    >
      <div
        ref="export_page_loading"
        class="spinner-border overlay_text"
        style="width: 5rem; height: 5rem;"
        role="status"
      />
      <p>Obteniendo datos</p>
    </div>
    <CCardHeader>
      <strong>{{ titulo }}</strong>
      <CButton
        color="primary"
        shape="pill"
        size="sm"
        class="float-right"
        :disabled="button_export_disabled"
        @click="exportToCSV"
      >
        <span
          ref="export_data_loading"
          class="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
          style="margin-right: 5px;"
        />

        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          width="16" 
          height="16" 
          fill="currentColor" 
          class="bi bi-file-earmark-bar-graph-fill" 
          viewBox="0 0 16 16"
        >
          <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zm.5 10v-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-2.5.5a.5.5 0 0 1-.5-.5v-4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1zm-3 0a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-1z" />
        </svg>
        Exportar a CSV
      </CButton>
    </CCardHeader>
    <CCardBody>
      <CForm :items="cuestionario">
        <CRow>
          <template
            v-for="(pregunta, index) in cuestionario.questions"
          >
            <CCol
              :key="pregunta.quest_question_id"
              md="6"
            >
              <CInput 
                :key="pregunta.quest_question_id"
                :value="pregunta.questions"
                :disabled="true"
              />
              <CSwitch
                :ref="'switch'+index"
                color="info"
                label-on="OFF"
                label-off="ON"
                checked="true"
                @update:checked="toggleLabels(index)"
              />
              <div
                :ref="'vacio' + pregunta.quest_question_id"
              />
              <div
                :id="'grafico' + pregunta.quest_question_id"
                :key="'grafico' + pregunta.quest_question_id"
                :ref="'grafico' + pregunta.quest_question_id"
                style="height: 300px"
              />
            </CCol>
          </template>
        </CRow>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<style scoped>
  #overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 1);
    transition: visibility 1s, opacity 1s linear;
    z-index: 666;
  }

  .overlay_text {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #67d07b;
    font-weight: bolder;
  }

</style>

<script>
import axios from "axios";
import "core-js/stable";
import "regenerator-runtime/runtime";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_spiritedaway from "@amcharts/amcharts4/themes/spiritedaway";

am4core.useTheme(am4themes_spiritedaway);
am4core.useTheme(am4themes_animated);

/**
 * Función para tomar los datos de un cuestionario con un id dado
 * 
 * @param {number} id - El ID del cuestionario a obtener
 * 
 * @returns {Promise<any>} Promesa que al completarse devuelve el cuestionario solicitado
 */
async function getCuestionarioData(id) {
  let data;
  const token = localStorage.token;
  try {
    await axios.get(
        process.env.VUE_APP_URL +
          process.env.VUE_APP_URL_CUESTIONARIO +
          id,
        {
          headers: {
            Authorization: token,
          },
        }
      ).then((result) => {
        data = result.data;
      }).catch(function (error) {
        if (error.response) {
          if (error.response.status == 401) {
            localStorage.removeItem("token");
            window.location = "#/pages/login";
          }
          if (error.response.status == 404) {
            window.location = "#/404";
          }
          if (error.response.status == 500) {
            window.location = "#/500";
          }
        }
      });
    return data;
  } catch (error) {
    window.location = "#/500";
  }
}

async function getStatsForQuestion(id) {
  let data;

  await axios
    .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_CUESTIONARIO + id + '/' + process.env.VUE_APP_URL_ANSWERS,
    {
      headers: {
        Authorization: localStorage.token,
      }
    }
    ).then((result) => {
      data = result.data;
    }).catch((error) => {
      if (error.response.status == 401) {
        localStorage.removeItem("token");
        window.location = "#/pages/login";
      }
      if (error.response.status == 404) window.location = "#/404";
      if (error.response.status == 500) window.location = "#/500";
    });

    return data;
}

async function checkToken(token) {
  await axios
    .get(process.env.VUE_APP_URL + process.env.VUE_APP_URL_CHECKTOKEN, {
      headers: {
        Authorization: token,
      },
    })
    .catch(function (error) {
      if (error.response) {
        if (error.response.status == 401) {
          localStorage.removeItem("token");
          window.location.href = "#/pages/login";
        }
        if (error.response.status == 404) {
          window.location = "#/404";
        }
        if (error.response.status == 500) {
          window.location = "#/500";
        }
      }
    });
}

export default {
  name: "CuestionarioStats",
  data() {
    return {
      id: null,
      titulo: "",
      cuestionario: {},
      charts: [],
      dataReadyToExport: new Map(),
      button_export_disabled: true
    }
  },
  created() {
    checkToken(localStorage.token);

    this.id = this.$route.params.id;

    this.recibirDatos();
  },
  beforeDestroy() {
    for(let chart of this.charts)
      if(Object.keys(chart).length) chart.dispose();
  },
  methods: {
    recibirDatos() {
      let data = getCuestionarioData(this.id);

      data.then((result) => {
        if(localStorage.token == undefined)
          return;

        this.cuestionario = this.checkAndPrepareData(result);

        this.titulo = "Información estadistica del formulario: " + this.cuestionario.title;

        this.createCharts();
      });
    },
    /**
     * Función que se encarga de recibir los datos por parametros
     * Y formatearlos para su correcta representacion
     * 
     * @param {object} data - Los datos a formatear para su correcta representación
     * 
     * @returns {object} Los datos formateados
     */
    checkAndPrepareData(data) {
      if(data.questions) {
        for(const question in data.questions) {
          if(data.questions[question].quest_question_id === null)
            data.questions[question].new_question_id = ++this.temp_question_id;

          if(!data.questions[question].answer) 
            data.questions[question].answer = [{}];
        }
      } else {
        data.questions = [{
            questions: "",
            new_question_id: ++this.temp_question_id,
            answer: []
        }];
      }

      return data;
    },
    async createCharts() {
      let cont = -1;

      for(let pregunta of this.cuestionario.questions){
        ++cont;
        let data = await getStatsForQuestion(pregunta.quest_question_id);

        this.dataReadyToExport.set(pregunta.questions.normalize('NFD').replaceAll(/\s+/g, '_').replace(/([\u0300-\u036f]|[^0-9a-zA-Z_])/g, ''), data);

        if(data.length){
          let grafo = am4core.create("grafico" + pregunta.quest_question_id, am4charts.PieChart);
          grafo.data = [];

          for(let stat of data)
            grafo.data.push(stat);

          let grafica = grafo.series.push(new am4charts.PieSeries());

          grafica.labels.template.maxWidth = 200;
          grafica.labels.template.wrap = true;

          grafica.dataFields.value = "number_answers";
          grafica.dataFields.category = "answer";
          grafica.slices.template.stroke = am4core.color("#fff");
          grafica.slices.template.strokeWidth = 2;
          grafica.slices.template.strokeOpacity = 1;

          grafica.slices.template.cornerRadius = 4;

          grafica.colors.step = 3;

          grafica.hiddenState.properties.opacity = 1;
          grafica.hiddenState.properties.endAngle = -90;
          grafica.hiddenState.properties.startAngle = -90;

          grafo.hiddenState.properties.radius = am4core.percent(0);

          grafo.legend = new am4charts.Legend();

          this.charts.push(grafo);

          continue;
        }

        this.$refs['vacio' + pregunta.quest_question_id][0].innerText = "Esta pregunta no cuenta con respuestas";
        this.$refs['switch' + cont][0].$el.style.display = "none";

        this.charts.push({});
      }

      this.$refs.export_data_loading.style.display = "none";
      this.$refs.overlay.style.opacity = "0";
      this.$refs.overlay.style.visibility = "hidden";
      this.button_export_disabled = false;
    },
    toggleLabels(index) {
      const state = !this.$refs['switch'+index][0].state;
      this.charts[index].series.values[0].labels.template.disabled = state;
      this.charts[index].series.values[0].ticks.template.disabled = state;
      this.charts[index].legend.disabled = state;
    },
    exportToCSV() {
      let content = "pregunta;respuesta;num_respuestas\r\n";

      for(let i of this.dataReadyToExport) {
        if(i[1].length) {
          for(let j of i[1]) {
            content += `${i[0]};${j.answer.normalize('NFD').replaceAll(/\s+/g, '_').replace(/([\u0300-\u036f]|[^0-9a-zA-Z_])/g, '')};${j.number_answers}\r\n`;
          }

          continue;
        }

        content += `${i[0]};;\r\n`;
      }

      let file = new Blob([content], {type: 'text/csv'});

      if(window.navigator.msSaveOrOpenBlob)
        window.navigator.msSaveOrOpenBlob(file, 'file.csv');
      else {
        let a = document.createElement("a");
        let url = URL.createObjectURL(file);

        a.href = url;
        a.download = 'file.csv';

        document.body.appendChild(a);

        a.click();

        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 0);
      }
    }
  }
}
</script>